import type { Carousel } from 'bootstrap';

export const useBootstrapCarousel = (
  element: Ref<HTMLElement>,
  options?: Partial<
    Carousel.Options & {
      cycleOnStart?: boolean;
    }
  >,
  onCarouselSlide?: (event: Carousel.Event) => void,
) => {
  const carouselInstance = ref<Carousel>();
  const instanceAvailable = ref(false);

  useEventListener(element, 'slide.bs.carousel', onCarouselSlide);

  const nextItem = () => {
    carouselInstance.value.next();
  };

  const prevItem = () => {
    carouselInstance.value.prev();
  };

  onMounted(async () => {
    if (element.value == null) return;
    const { default: Carousel } = await import('bootstrap/js/dist/carousel');
    carouselInstance.value = new Carousel(element.value, options);

    if (options?.cycleOnStart) {
      carouselInstance.value.cycle();
    }

    instanceAvailable.value = true;
  });

  onUnmounted(() => {
    if (carouselInstance.value == null) return;
    carouselInstance.value.dispose();
  });

  return {
    instanceAvailable,
    carouselInstance,
    nextItem,
    prevItem,
  };
};

export default useBootstrapCarousel;
